export const BASE_API = "https://langosta-qr-menu-django.herokuapp.com";
export const TOKEN = "token";

export const ORDER_STATUS = {
  PENDING: "PENDING",
  DELIVERED: "DELIVERED",
};

export const PAYMENT_STATUS = {
  PENDING: "PENDING",
  PAID: "PAID",
};
